<template>
    <BT-Blade
        :actualUsedHeight="96"
        bladeName="company-settings"
        :canRefresh="false"
        :loadingMsg="loadingMsg"
        title="Account Settings">
        <template #toolbar-left>
            <v-slide-x-transition>
                <v-btn v-if="needsSaving" class="primary mx-5" small @click="saveAllChanges">Save Changes</v-btn>
            </v-slide-x-transition>
        </template>
        <template>
            <v-card
                class="mx-auto overflow-y-auto"
                flat
                style="height: calc(100vh - 96px)"
                width="900">
                <BT-Settings-Slider
                    :hideHeader="roleTab == 1"
                    :options="[
                        { title: 'Personal Profile', value: 'personal' },
                        { title: 'Company Profile', value: 'company', disabled: !$canView('company-settings') },
                        { title: 'Processes & Automation', value: 'processes' },
                        { title: 'Email Configuration', value: 'email', disabled: !$canView('email-configurations') },
                        { title: 'Notifications', value: 'notifications' },
                        { title: 'Subscription & Billing', value: 'subscription', disabled: !$canView('billing-settings') },
                        { title: 'Roles & Permissions', value: 'roles' },
                        { title: 'Integration Settings', value: 'integration' }]"
                    :width="900">
                    <template #personal>
                        <BT-Blade-Item
                            @changed="isChanged => { profileToggleToChange = isChanged; }"
                            bladeName="my-profile"
                            :canDelete="false"
                            :canSave="false"
                            hideBladeHeader
                            ignoreID
                            navigation="my-profile"
                            :onPullSuccessAsync="loadPermissions"
                            :saveToggle="saveProfileToggle"
                            :scroll="false"
                            startEditing>
                            <template v-slot="{ item, data }">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                :rules="requiredRules()"
                                                required
                                                v-model="item.userName"
                                                label="User Name"
                                                :isEditing="data.isEditing || data.isNew" />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                :rules="requiredRules()"
                                                v-model="item.email"
                                                label="Email Address"
                                                :isEditing="data.isEditing || data.isNew" />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                v-model="item.firstName"
                                                label="First Name"
                                                :isEditing="data.isEditing || data.isNew" />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                v-model="item.lastName"
                                                label="Last Name"
                                                :isEditing="data.isEditing || data.isNew" />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                v-model="item.phoneNumber"
                                                label="Phone Number"
                                                :isEditing="data.isEditing || data.isNew" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider class="my-2" />
                                            <v-subheader>Roles & Permissions</v-subheader>
                                        </v-col>
                                        <v-col v-if="item.isGlobalAdmin" cols="12">
                                            <v-card>
                                                <v-card-title>Global Admin</v-card-title>
                                                <v-card-text>
                                                    You have access to absolutely everything.
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <BT-List
                                                :items="item.roles"
                                                label="Your Roles"
                                                showList>
                                                <template #listItem="{ item }">
                                                    <v-card>
                                                        <v-card-title>{{ item.roleName }}</v-card-title>
                                                        <v-card-text>{{ item.description }}</v-card-text>
                                                    </v-card>
                                                </template>
                                            </BT-List>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <BT-List
                                                :items="permissions"
                                                label="Your Permissions"
                                                showList>
                                                <template #listItem="{ item }">
                                                    <v-list-item>
                                                        <v-list-item-action>
                                                            <v-row dense>
                                                                <v-icon title="View" small :class="item.canView || item.canEdit ? 'success--text' : ''" left>mdi-eye</v-icon>
                                                                <v-icon title="Edit" small :class="item.canEdit ? 'success--text' : ''" right>mdi-pencil</v-icon>
                                                            </v-row>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            {{ item.text }}
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </BT-List>
                                        </v-col>
                                    </v-row>
                                </v-container>

                                <!-- <v-container v-if="item.isGlobalAdmin">
                                    <v-row>
                                        <v-col cols="12">
                                            **Global Admin** - means has access to absolutely everything.
                                        </v-col>
                                    </v-row>
                                </v-container> -->

                                <!-- <v-expansion-panels v-model="personalProfilePanelV" multiple>
                                    <BT-Blade-Expansion-Items
                                        addBladeName="role"
                                        :canExportCSV="false"
                                        :canRefresh="false"
                                        :headers="[
                                            { text: 'Role Name', value: 'roleName', title: 1 },
                                            { text: 'Description', value: 'description', subtitle: 1 }]"
                                        hideActions
                                        :items="item.roles"
                                        showList
                                        title="Roles" />
                                    <BT-Blade-Expansion-Items
                                        :canExportCSV="false"
                                        :canRefresh="false"
                                        :canSelect="false"
                                        hideActions
                                        :items="item.roles"
                                        :onPullSuccessAsync="pullPermissions"
                                        showList
                                        title="Permissions">
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-action>
                                                <v-row dense>
                                                    <v-icon title="View" small :class="item.canView || item.canEdit ? 'success--text' : ''" left>mdi-eye</v-icon>
                                                    <v-icon title="Edit" small :class="item.canEdit ? 'success--text' : ''" right>mdi-pencil</v-icon>
                                                </v-row>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                {{ item.text }}
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Expansion-Items>
                                </v-expansion-panels> -->
                            </template> 
                        </BT-Blade-Item>
                    </template>
                    <template #company>
                        <BT-Blade-Item
                            @changed="isChanged => { companyToggleToChange = isChanged; }"
                            :canSave="false"
                            hideBladeHeader
                            ignoreID
                            navigation="company-settings"
                            :saveToggle="saveCompanyToggle"
                            :scroll="false"
                            startEditing>
                            <template v-slot="{ item, data }">
                                <v-container>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                v-model="item.accountName"
                                                :rules="requiredRules()"
                                                label="Account Name"
                                                :isEditing="data.isEditing" />

                                            <BT-Field-String
                                                v-model="item.companyName"
                                                :rules="requiredRules()"
                                                label="Company Name"
                                                :isEditing="data.isEditing" />

                                            <BT-Field-String
                                                v-model="item.primaryEmail"
                                                :rules="emailRules()"
                                                label="Primary Email Address"
                                                :isEditing="data.isEditing" />
                                            
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <BT-Field-String
                                                v-model="item.website"
                                                label="Website"
                                                :isEditing="data.isEditing" />

                                            <BT-Field-String
                                                v-model="item.phoneNumber"
                                                label="Phone Number"
                                                :isEditing="data.isEditing" />
                                            
                                            <BT-Field-Select
                                                :items="timeZones"
                                                label="Default Timezone"
                                                :rules="requiredRules()"
                                                v-model="item.defaultTimeZone"
                                                :isEditing="data.isEditing" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card class="mb-2">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="6">
                                                            <v-card-title>Company Logo</v-card-title>
                                                            <v-card-subtitle>Size: 256px(W) x 256px(H)</v-card-subtitle>
                                                            <v-card-text>The logo shows in many different places such as beside your company name in lists, on your company page, in emails, and when customers link up with your company.</v-card-text>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                        <BT-Image-Edit
                                                            justify-center
                                                            navigation="company-image"
                                                            placeholder="mdi-factory"
                                                            class="mx-auto"
                                                            :src="companyLogoURL($BlitzIt.auth.session.data.companyAccountID) + '?' + cacheData"
                                                            @uploaded="cacheData = new Date().getTime()" /> 
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card class="mb-2">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="6">
                                                            <v-card-title>Banner</v-card-title>
                                                            <v-card-subtitle>Size: 650px(W) x 250px(H)</v-card-subtitle>
                                                            <v-card-text>Your banner shows at the top of your public company page.  It helps gives your page visitors the vibe of what your business is all about.</v-card-text>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                        <BT-Image-Edit
                                                            :dialogWidth="900"
                                                            :height="100"
                                                            :width="260"
                                                            :imageHeight="250"
                                                            :imageWidth="650"
                                                            justify-center
                                                            navigation="company-banner"
                                                            placeholder="mdi-factory"
                                                            class="mx-auto"
                                                            :src="companyBannerURL($BlitzIt.auth.session.data.companyAccountID) + '?' + cacheData"
                                                            @uploaded="cacheData = new Date().getTime()" /> 
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card class="mb-2">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="6">
                                                        <v-card-title>Theme Colour</v-card-title>
                                                        <v-card-text>This defines the background color of your company page - particularly the sides and the ribbon below your banner.  You can select any RGB/Hex/HSL colour.</v-card-text>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                        <BT-Entity
                                                            @changed="isChanged => { supplierSettingsToChange = isChanged; }"
                                                            ignoreID
                                                            navigation="supplier-settings"
                                                            :saveToggle="saveSupplierSettingsToggle"
                                                            single>
                                                            <template v-slot="{ item }">
                                                                <v-hover v-slot="{ hover }">
                                                                    <v-container>
                                                                        <v-row>
                                                                            <v-col cols="12">
                                                                                <v-card 
                                                                                    :color="item.themeColour"
                                                                                    width="100%"
                                                                                    height="100px"
                                                                                    class="d-flex justify-center align-center"
                                                                                    @click="colorToggle = !colorToggle"
                                                                                    :dark="item.themeColour != null">
                                                                                    <div class="mx-4">Theme Colour</div>
                                                                                    <BT-Color-Picker-Menu
                                                                                        v-model="item.themeColour"
                                                                                        :openToggle="colorToggle" />
                                                                                        <!-- @change="save(item)" -->

                                                                                    <v-fade-transition>
                                                                                        <v-overlay v-if="hover" absolute :opacity="0.75">
                                                                                            <div><v-icon left>mdi-pencil</v-icon>Edit</div>
                                                                                        </v-overlay>
                                                                                    </v-fade-transition>
                                                                                </v-card>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-hover>
                                                            </template>
                                                        </BT-Entity>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card class="mb-2">
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="6">
                                                        <v-card-title>Background Image</v-card-title>
                                                        <v-card-subtitle>Size: 1200px(W) x 800px(H)</v-card-subtitle>
                                                        <v-card-text>The background image will be used for every company user.  It also shows when customers are placing new orders to your company.</v-card-text>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                        <BT-Image-Edit
                                                            :dialogWidth="null"
                                                            :height="100"
                                                            :width="150"
                                                            :imageHeight="800"
                                                            :imageWidth="1200"
                                                            justify-center
                                                            navigation="company-ordering-background"
                                                            placeholder="mdi-image-area"
                                                            class="mx-auto"
                                                            :src="companyOrderingBackgroundURL($BlitzIt.auth.session.data.companyAccountID) + '?' + cacheData"
                                                            @uploaded="cacheData = new Date().getTime()" /> 
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <BT-Entity
                                            @changed="isChanged => { supplierSettingsToChange = isChanged; }"
                                            ignoreID
                                            navigation="supplier-settings"
                                            :saveToggle="saveSupplierSettingsToggle"
                                            single>
                                            <template v-slot="{ item }">
                                                <v-col cols="12">
                                                    <v-card class="my-2">
                                                        <v-row no-gutters>
                                                            <v-col cols="12" sm="6">
                                                                <v-card-title>Public Supplier</v-card-title>
                                                                <v-card-text>Write a couple paragraphs about what your company is on about as a supplier.  This description will be shown on your page.  Alternatively you can hide your company from being seen by the public.</v-card-text>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <BT-Square-Check
                                                                                v-model="item.isPublicSupplier"
                                                                                falseIcon="mdi-incognito-circle"
                                                                                falseLabel="Private"
                                                                                isEditing
                                                                                trueIcon="mdi-earth"
                                                                                trueLabel="Public" />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-slide-y-transition>
                                                                    <BT-Field-Text-Area
                                                                        :disabled="!item.isPublicSupplier"
                                                                        isEditing
                                                                        label="Your description about what you're on about for all to see"
                                                                        v-model="item.publicDescription" />
                                                                </v-slide-y-transition>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <v-card 
                                                                    @click="item.isOpenToAgreementRequests = !item.isOpenToAgreementRequests"
                                                                    class="ma-1 pa-1"
                                                                    :disabled="!item.isPublicSupplier"
                                                                    height="285">
                                                                    <div class="text-center">
                                                                        <v-icon size="50">mdi-handshake</v-icon>
                                                                    </div>
                                                                    <v-card-title>New Requests</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <v-switch 
                                                                            v-model="item.isOpenToAgreementRequests"
                                                                            disabled
                                                                            hide-details
                                                                            inset
                                                                            :label="item.isOpenToAgreementRequests ? 'ON' : 'OFF'" />
                                                                    </v-card-subtitle>
                                                                    <v-card-text>Potential new customers can request your services as a supplier through BlitzIt Web.</v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <v-card 
                                                                    @click="item.autoApproveAgreementRequests = !item.autoApproveAgreementRequests"
                                                                    class="ma-1 pa-1"
                                                                    :disabled="!item.isPublicSupplier || !item.isOpenToAgreementRequests"
                                                                    height="285">
                                                                    <div class="text-center">
                                                                        <v-icon v-if="item.autoApproveAgreementRequests" size="50">mdi-robot</v-icon>
                                                                        <v-icon v-else size="50">mdi-hand-back-left</v-icon>
                                                                    </div>
                                                                    <v-card-title>Approve Requests</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <v-switch 
                                                                            v-model="item.autoApproveAgreementRequests"
                                                                            disabled
                                                                            hide-details
                                                                            inset
                                                                            :label="item.autoApproveAgreementRequests ? 'Auto' : 'Manual'" />
                                                                    </v-card-subtitle>
                                                                    <v-card-text>When other business request to connect you can automatically connect or manually approve each individual request.</v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <v-card 
                                                                    @click="selectCustomerTemplate(item)"
                                                                    class="ma-1 pa-1"
                                                                    :disabled="!item.isPublicSupplier || !item.isOpenToAgreementRequests || !item.autoApproveAgreementRequests"
                                                                    height="285">
                                                                    <div class="text-center">
                                                                        <v-icon size="50">mdi-account</v-icon>
                                                                    </div>
                                                                    <v-card-title>Default Settings</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <div class="d-flex">
                                                                            <v-icon v-if="item.defaultTemplateID != null"
                                                                                @click.stop="item.defaultTemplateID = null"
                                                                                class="error--text"
                                                                                left>mdi-close</v-icon>
                                                                            <BT-Entity
                                                                                alternateText="None Selected"
                                                                                :itemValue="item.defaultTemplateID"
                                                                                navigation="customer-templates"
                                                                                itemText="templateName" />
                                                                        </div>
                                                                    </v-card-subtitle>
                                                                    <v-card-text>When you auto approve new customers, you can create and choose a customer template that defines their default original settings.</v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                            </template>
                                        </BT-Entity>
                                        <BT-Entity
                                            @changed="isChanged => { courierSettingsToChange = isChanged; }"
                                            ignoreID
                                            navigation="courier-settings"
                                            :saveToggle="saveCourierSettingsToggle"
                                            single>
                                            <template v-slot="{ item }">
                                                <v-col cols="12">
                                                    <v-card class="my-2">
                                                        <v-row no-gutters>
                                                            <v-col cols="12" sm="6">
                                                                <v-card-title>Public Courier</v-card-title>
                                                                <v-card-text>Write a couple paragraphs about what your company is on about as a courier.  This description will be shown on your page.  Alternatively you can hide your company from being seen by the public.</v-card-text>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <BT-Square-Check
                                                                                v-model="item.isPublicCourier"
                                                                                falseIcon="mdi-incognito-circle"
                                                                                falseLabel="Private"
                                                                                isEditing
                                                                                trueIcon="mdi-earth"
                                                                                trueLabel="Public" />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-slide-y-transition>
                                                                    <BT-Field-Text-Area
                                                                        :disabled="!item.isPublicCourier"
                                                                        isEditing
                                                                        label="Your description about what you're on about for all to see"
                                                                        v-model="item.publicDescription" />
                                                                </v-slide-y-transition>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <v-card 
                                                                    @click="item.isOpenToAgreementRequests = !item.isOpenToAgreementRequests"
                                                                    class="ma-1 pa-1"
                                                                    :disabled="!item.isPublicCourier"
                                                                    height="285">
                                                                    <div class="text-center">
                                                                        <v-icon size="50">mdi-handshake</v-icon>
                                                                    </div>
                                                                    <v-card-title>New Requests</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <v-switch 
                                                                            v-model="item.isOpenToAgreementRequests"
                                                                            disabled
                                                                            hide-details
                                                                            inset
                                                                            :label="item.isOpenToAgreementRequests ? 'ON' : 'OFF'" />
                                                                    </v-card-subtitle>
                                                                    <v-card-text>Potential new clients can request your services as a courier through BlitzIt Web.</v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <v-card 
                                                                    @click="item.autoApproveAgreementRequests = !item.autoApproveAgreementRequests"
                                                                    class="ma-1 pa-1"
                                                                    :disabled="!item.isPublicCourier || !item.isOpenToAgreementRequests"
                                                                    height="285">
                                                                    <div class="text-center">
                                                                        <v-icon v-if="item.autoApproveAgreementRequests" size="50">mdi-robot</v-icon>
                                                                        <v-icon v-else size="50">mdi-hand-back-left</v-icon>
                                                                    </div>
                                                                    <v-card-title>Approve Requests</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <v-switch 
                                                                            v-model="item.autoApproveAgreementRequests"
                                                                            disabled
                                                                            hide-details
                                                                            inset
                                                                            :label="item.autoApproveAgreementRequests ? 'Auto' : 'Manual'" />
                                                                    </v-card-subtitle>
                                                                    <v-card-text>When other business request to connect you can automatically connect or manually approve each individual request.</v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <v-card 
                                                                    @click="selectClientTemplate(item)"
                                                                    class="ma-1 pa-1"
                                                                    :disabled="!item.isPublicCourier || !item.isOpenToAgreementRequests || !item.autoApproveAgreementRequests"
                                                                    height="285">
                                                                    <div class="text-center">
                                                                        <v-icon size="50">mdi-account</v-icon>
                                                                    </div>
                                                                    <v-card-title>Default Settings</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <div class="d-flex">
                                                                            <v-icon v-if="item.defaultTemplateID != null"
                                                                                @click.stop="item.defaultTemplateID = null"
                                                                                class="error--text"
                                                                                left>mdi-close</v-icon>
                                                                            <BT-Entity
                                                                                alternateText="None Selected"
                                                                                :itemValue="item.defaultTemplateID"
                                                                                navigation="customer-templates"
                                                                                itemText="templateName" />
                                                                        </div>
                                                                    </v-card-subtitle>
                                                                    <v-card-text>When you auto approve new clients, you can create and choose a client template that defines their default original settings.</v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-card class="my-2">
                                                        <v-row no-gutters>
                                                            <v-col cols="12" sm="6">
                                                                <v-card-title>3PL Services</v-card-title>
                                                                <v-card-text>Does your company provide 3PL services and do you want to be available for the public to use?</v-card-text>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <BT-Square-Check
                                                                                v-model="item.isPublic3PLService"
                                                                                falseIcon="mdi-light-switch"
                                                                                falseLabel="OFF"
                                                                                isEditing
                                                                                trueIcon="mdi-light-switch-off"
                                                                                trueLabel="ON" />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                            </template>
                                        </BT-Entity>
                                    </v-row>
                                </v-container>
                            </template>
                        </BT-Blade-Item>
                    </template>
                    <template #processes>
                        <v-container style="max-width: 900px;">
                            <v-row no-gutters>
                                <BT-Entity
                                    @changed="isChanged => { supplierSettingsToChange = isChanged; }"
                                    ignoreID
                                    navigation="supplier-settings"
                                    :saveToggle="saveSupplierSettingsToggle"
                                    single>
                                    <template v-slot="{ item }">
                                        <v-col cols="12">
                                            <v-card class="ma-1">
                                                <v-card-title>Orders</v-card-title>
                                                <v-card-text>
                                                    Here are your controls to manage when orders are created, approved, processed, and emailed.
                                                </v-card-text>
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon :class="item.standingOrderAutomation == 'Off' ? 'error--text' : 'success--text'" size="50">mdi-robot</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Order Generation</v-card-title>
                                                            <v-card-subtitle>
                                                                <BT-Select-Automation
                                                                    buttonHeight="45px"
                                                                    v-model="item.standingOrderAutomation" />
                                                            </v-card-subtitle>
                                                            <v-card-text>How often you want BlitzIt Web to generate any standing orders that are due.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon size="50">mdi-counter</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Order Numbering</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-text-field
                                                                    v-model.number="item.lastCustomerOrderNumber"
                                                                    prepend-icon="#"
                                                                    singleLine
                                                                    type="number"
                                                                    hideDetails />
                                                            </v-card-subtitle>
                                                            <v-card-text>Every order is given a customer order number as a reference.  This number increases as each order is created.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            @click="item.emailNewOrders = !item.emailNewOrders"
                                                            class="ma-1 pa-1"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon :class="item.emailNewOrders ? 'success--text' : 'error--text'" size="50">mdi-email-fast</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">New Order Emails</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.emailNewOrders"
                                                                    disabled
                                                                    hide-details
                                                                    inset
                                                                    :label="item.emailNewOrders ? 'ON' : 'OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>Send emails to customers when they create new orders just to signal that you've received it.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            @click="item.emailConfirmedOrders = !item.emailConfirmedOrders"
                                                            class="ma-1 pa-1"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon :class="item.emailConfirmedOrders ? 'success--text' : 'error--text'" size="50">mdi-email-fast</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Confirmation Emails</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.emailConfirmedOrders"
                                                                    disabled
                                                                    hide-details
                                                                    inset
                                                                    :label="item.emailConfirmedOrders ? 'ON' : 'OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>Send emails to customers when their order is confirmed.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </template>
                                </BT-Entity>
                                <BT-Entity
                                    @changed="isChanged => { stockSettingsToChange = isChanged; }"
                                    ignoreID
                                    navigation="picking-settings"
                                    :saveToggle="saveStockSettingsToggle"
                                    single>
                                    <template v-slot="{ item }">
                                        <v-col cols="12">
                                            <v-card class="ma-1">
                                                <v-card-title>Consignments</v-card-title>
                                                <v-card-text>
                                                    Manage controls for processing consignments, provisioning and assigning stock, auto-calculating weights and packages, and also archiving.
                                                </v-card-text>
                                                <v-row no-gutters>
                                                    <!-- <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            :disabled="item.invoiceNumberTrigger != 'Auto'"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon size="50">mdi-counter</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6"> Numbering</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-text-field
                                                                    v-model.number="item.lastInvoiceNumber"
                                                                    placeholder="Last Invoice Number"
                                                                    prepend-icon="#"
                                                                    singleLine
                                                                    type="number"
                                                                    hideDetails />
                                                            </v-card-subtitle>
                                                            <v-card-text>Every invoice is given an invoice number as a reference.  This number increases for each new invoice.</v-card-text>
                                                        </v-card>
                                                    </v-col> -->
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.consignmentGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.consignmentTrigger == 'Manual'" class="error--text" size="50">mdi-earth</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Order Consigning</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.consignmentGuide" />
                                                                Once an order is processed it is ready to be consigned for delivery.  This can be done either automatically or manually by the user.
                                                                <BT-Select-Automation
                                                                    defaultValue="Manual"
                                                                    :disabled="item.consignmentGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.consignmentTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.provisionGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.provisionTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Stock Provisioning</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.provisionGuide" />
                                                                Consignments can have stock assigned to them for picking.  This can be attempted automatically or manually by the user.
                                                                <BT-Select-Automation
                                                                    defaultValue="Auto"
                                                                    :disabled="item.provisionGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.provisionTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.provisionStrategy == 'FirstInFirstOut'" size="50">mdi-queue-first-in-last-out</v-icon>
                                                                    <v-icon v-else-if="item.provisionStrategy == 'Freshest'" size="50">mdi-tray-full</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Provisioning Strategy</v-card-title>
                                                            <v-card-text>
                                                                When stock is assigned automatically to a consignment, you can instruct BlitzIt Web what strategy to prefer.
                                                                <BT-Select-Automation
                                                                    defaultValue="FirstInFirstOut"
                                                                    :icon="null"
                                                                    :options="[
                                                                        { name: 'FIFO', value: 'FirstInFirstOut' },
                                                                        { name: 'Freshest', value: 'Freshest' }]"
                                                                    v-model="item.provisionStrategy" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.dispatchRequestGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.dispatchRequestTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Courier Processing</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.dispatchRequestGuide" />
                                                                Consignments can become available for courier organizing automatically by BlitzIt Web or manually by a user.
                                                                <BT-Select-Automation
                                                                    defaultValue="Auto"
                                                                    :disabled="item.dispatchRequestGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.dispatchRequestTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            @click="item.mustFullyProvision = !item.mustFullyProvision"
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon v-if="item.mustFullyProvision" class="success--text" size="50">mdi-tray-alert</v-icon>
                                                                <v-icon v-else class="error--text" size="50">mdi-tray-alert</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Require Provisioning</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.mustFullyProvision"
                                                                    disabled
                                                                    hide-details
                                                                    inset
                                                                    :label="item.mustFullyProvision ? 'Required: ON' : 'Required: OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>
                                                                You can prevent BlitzIt Web from automatically proceeding until the consignment has all the stock it needs.
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            @click="item.calculateWeight = !item.calculateWeight"
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.calculateWeight" class="success--text" size="50">mdi-weight</v-icon>
                                                                    <v-icon v-else class="error--text" size="50">mdi-weight</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Calculate Weight</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.calculateWeight"
                                                                    disabled
                                                                    hide-details
                                                                    inset
                                                                    :label="item.calculateWeight ? 'ON' : 'OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>
                                                                BlitzIt Web can attempt to calculate the weight of the consignment.  Weight is calculated based off the weight property of each product.
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.calculateConsignmentPackages" class="success--text" size="50">mdi-package-variant</v-icon>
                                                                    <v-icon v-else class="error--text" size="50">mdi-package-variant</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Calculate Packages</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.calculateConsignmentPackages"
                                                                    hide-details
                                                                    inset
                                                                    :label="item.calculateConsignmentPackages ? 'ON' : 'OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>
                                                                BlitzIt Web can attempt to calculate how many packages the consignment will include.
                                                                <v-subheader>Packages ({{ item.consignmentPackageIDs == null || item.consignmentPackageIDs.length == 0 ? '0' : item.consignmentPackageIDs.split(',').length }})</v-subheader>
                                                                <BT-Select-List
                                                                    v-if="item.calculateConsignmentPackages"
                                                                    v-model="item.consignmentPackageIDs"
                                                                    chips
                                                                    height="150px"
                                                                    itemText="measurementName"
                                                                    itemValue="id"
                                                                    listClass="overflow-y-auto ma-0 pa-0"
                                                                    multiple
                                                                    returnCSV
                                                                    outlined
                                                                    navigation="measurements" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.archiveGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.archiveTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Consignment Archiving</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.archiveGuide" />
                                                                Once a consignment's life is completed it will be archived.  This can happen automatically or the system will wait for a user to manually do it.
                                                                <BT-Select-Automation
                                                                    defaultValue="Auto"
                                                                    :disabled="item.archiveGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.archiveTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </template>
                                </BT-Entity>
                                <!-- <BT-Entity
                                    @changed="isChanged => { courierSettingsToChange = isChanged; }"
                                    ignoreID
                                    navigation="courier-settings"
                                    :saveToggle="saveCourierSettingsToggle"
                                    single>
                                    <template v-slot="{ item }">
                                        <v-col cols="12">
                                            <v-card class="ma-1">
                                                <v-card-title>Couriering</v-card-title>
                                                <v-card-text>
                                                    Settings for controlling couriers, routing, planning, and delivering of consignments.
                                                </v-card-text>
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.consignmentGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.consignmentTrigger == 'Manual'" class="error--text" size="50">mdi-earth</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Order Consigning</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.consignmentGuide" />
                                                                Once an order is processed it is ready to be consigned for delivery.  This can be done either automatically or manually by the user.
                                                                <BT-Select-Automation
                                                                    defaultValue="Manual"
                                                                    :disabled="item.consignmentGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.consignmentTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.provisionGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.provisionTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Stock Provisioning</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.provisionGuide" />
                                                                Consignments can have stock assigned to them for picking.  This can be attempted automatically or manually by the user.
                                                                <BT-Select-Automation
                                                                    defaultValue="Auto"
                                                                    :disabled="item.provisionGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.provisionTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.provisionStrategy == 'FirstInFirstOut'" size="50">mdi-queue-first-in-last-out</v-icon>
                                                                    <v-icon v-else-if="item.provisionStrategy == 'Freshest'" size="50">mdi-tray-full</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Provisioning Strategy</v-card-title>
                                                            <v-card-text>
                                                                When stock is assigned automatically to a consignment, you can instruct BlitzIt Web what strategy to prefer.
                                                                <BT-Select-Automation
                                                                    defaultValue="FirstInFirstOut"
                                                                    :icon="null"
                                                                    :options="[
                                                                        { name: 'FIFO', value: 'FirstInFirstOut' },
                                                                        { name: 'Freshest', value: 'Freshest' }]"
                                                                    v-model="item.provisionStrategy" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.dispatchRequestGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.dispatchRequestTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Courier Processing</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.dispatchRequestGuide" />
                                                                Consignments can become available for courier organizing automatically by BlitzIt Web or manually by a user.
                                                                <BT-Select-Automation
                                                                    defaultValue="Auto"
                                                                    :disabled="item.dispatchRequestGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.dispatchRequestTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            @click="item.mustFullyProvision = !item.mustFullyProvision"
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon v-if="item.mustFullyProvision" class="success--text" size="50">mdi-tray-alert</v-icon>
                                                                <v-icon v-else class="error--text" size="50">mdi-tray-alert</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Require Provisioning</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.mustFullyProvision"
                                                                    disabled
                                                                    hide-details
                                                                    inset
                                                                    :label="item.mustFullyProvision ? 'Required: ON' : 'Required: OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>
                                                                You can prevent BlitzIt Web from automatically proceeding until the consignment has all the stock it needs.
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            @click="item.calculateWeight = !item.calculateWeight"
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.calculateWeight" class="success--text" size="50">mdi-weight</v-icon>
                                                                    <v-icon v-else class="error--text" size="50">mdi-weight</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Calculate Weight</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.calculateWeight"
                                                                    disabled
                                                                    hide-details
                                                                    inset
                                                                    :label="item.calculateWeight ? 'ON' : 'OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>
                                                                BlitzIt Web can attempt to calculate the weight of the consignment.  Weight is calculated based off the weight property of each product.
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.calculateConsignmentPackages" class="success--text" size="50">mdi-package-variant</v-icon>
                                                                    <v-icon v-else class="error--text" size="50">mdi-package-variant</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Calculate Packages</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-switch 
                                                                    v-model="item.calculateConsignmentPackages"
                                                                    hide-details
                                                                    inset
                                                                    :label="item.calculateConsignmentPackages ? 'ON' : 'OFF'" />
                                                            </v-card-subtitle>
                                                            <v-card-text>
                                                                BlitzIt Web can attempt to calculate how many packages the consignment will include.
                                                                <v-subheader>Packages ({{ item.consignmentPackageIDs == null || item.consignmentPackageIDs.length == 0 ? '0' : item.consignmentPackageIDs.split(',').length }})</v-subheader>
                                                                <BT-Select-List
                                                                    v-if="item.calculateConsignmentPackages"
                                                                    v-model="item.consignmentPackageIDs"
                                                                    chips
                                                                    height="150px"
                                                                    itemText="measurementName"
                                                                    itemValue="id"
                                                                    listClass="overflow-y-auto ma-0 pa-0"
                                                                    multiple
                                                                    returnCSV
                                                                    outlined
                                                                    navigation="measurements" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.archiveGuide == 'Agreements'" size="50">mdi-account</v-icon>
                                                                    <v-icon v-else-if="item.archiveTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Consignment Archiving</v-card-title>
                                                            <v-card-text>
                                                                Manage this on a global level which applies to everyone or individually for each customer.
                                                                <BT-Select-Automation
                                                                    buttonClass="mb-2"
                                                                    defaultValue="Settings"
                                                                    :icon="null"
                                                                    useGuideOptions
                                                                    v-model="item.archiveGuide" />
                                                                Once a consignment's life is completed it will be archived.  This can happen automatically or the system will wait for a user to manually do it.
                                                                <BT-Select-Automation
                                                                    defaultValue="Auto"
                                                                    :disabled="item.archiveGuide == 'Agreements'"
                                                                    :icon="null"
                                                                    useTriggerOptions
                                                                    v-model="item.archiveTrigger" />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </template>
                                </BT-Entity> -->
                                <BT-Entity
                                    @changed="isChanged => { supplierSettingsToChange = isChanged; }"
                                    ignoreID
                                    navigation="supplier-settings"
                                    :saveToggle="saveSupplierSettingsToggle"
                                    single>
                                    <template v-slot="{ item }">
                                        <v-col cols="12">
                                            <v-card class="ma-1">
                                                <v-card-title>Invoicing</v-card-title>
                                                <v-card-text>Orders eventually become invoices.  Once it's clear what was delivered, BlitzIt Web can convert the order into an invoice.  Here are some important controls to help manage this process.</v-card-text>
                                                <v-row no-gutters>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon v-if="item.invoiceAutomation == null || item.invoiceAutomation == 'Off'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Scheduled Invoicing</v-card-title>
                                                            <v-card-subtitle>
                                                                <BT-Select-Automation
                                                                    buttonHeight="45px"
                                                                    v-model="item.invoiceAutomation" />
                                                            </v-card-subtitle>
                                                            <v-card-text>If you have auto-invoicing turned off for customers, you can configure BlitzIt Web to regularly convert completed orders into invoices.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-slide-x-transition hide-on-leave>
                                                                    <v-icon v-if="item.invoiceNumberTrigger == 'OrderNumber'" class="success--text" size="50">mdi-content-copy</v-icon>
                                                                    <v-icon v-else-if="item.invoiceNumberTrigger == 'Manual'" class="error--text" size="50">mdi-hand-back-left</v-icon>
                                                                    <v-icon v-else size="50" class="success--text">mdi-robot</v-icon>
                                                                </v-slide-x-transition>
                                                            </div>
                                                            <v-card-title class="text-h6">Numbering Behaviour</v-card-title>
                                                            <v-card-subtitle>
                                                                <BT-Select-Automation
                                                                    buttonHeight="45px"
                                                                    :icon="null"
                                                                    :options="[
                                                                        { name: 'Manual', value: 'Manual' },
                                                                        { name: 'Auto', value: 'Auto' },
                                                                        { name: 'Copy Order Numbers', value: 'OrderNumber'}]"
                                                                    v-model="item.invoiceNumberTrigger" />
                                                            </v-card-subtitle>
                                                            <v-card-text>An invoice can inherit the original order number as its invoice number or you can create a whole separate line of numbering.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            :disabled="item.invoiceNumberTrigger != 'Auto'"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon size="50">mdi-counter</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Invoice Numbering</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-text-field
                                                                    v-model.number="item.lastInvoiceNumber"
                                                                    placeholder="Last Invoice Number"
                                                                    prepend-icon="#"
                                                                    singleLine
                                                                    type="number"
                                                                    hideDetails />
                                                            </v-card-subtitle>
                                                            <v-card-text>Every invoice is given an invoice number as a reference.  This number increases for each new invoice.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <v-card
                                                            class="ma-1 pa-1"
                                                            :disabled="item.invoiceNumberTrigger != 'Auto'"
                                                            height="265"
                                                            outlined>
                                                            <div class="text-center">
                                                                <v-icon size="50">mdi-counter</v-icon>
                                                            </div>
                                                            <v-card-title class="text-h6">Number Prefixing</v-card-title>
                                                            <v-card-subtitle>
                                                                <v-text-field
                                                                    v-model.number="item.invoicePrefix"
                                                                    placeholder="Prefix"
                                                                    prepend-icon="#"
                                                                    singleLine
                                                                    type="number"
                                                                    hideDetails />
                                                            </v-card-subtitle>
                                                            <v-card-text>You might like to give the invoice number a prefix of some sort.  Here is where you can do that.</v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <BT-Entity
                                                            @changed="isChanged => { orderInvoicingSettingsToChange = isChanged; }"
                                                            ignoreID
                                                            navigation="invoicing-settings"
                                                            :saveToggle="saveOrderInvoicingSettingsToggle"
                                                            single>
                                                            <template v-slot="{ item }">
                                                                <v-card
                                                                    @click="item.emailNewInvoices = !item.emailNewInvoices"
                                                                    class="ma-1 pa-1"
                                                                    height="265"
                                                                    outlined>
                                                                    <div class="text-center">
                                                                        <v-icon :class="item.emailNewInvoices ? 'success--text' : 'error--text'" size="50">mdi-email-fast</v-icon>
                                                                    </div>
                                                                    <v-card-title class="text-h6">New Invoice Emails</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <v-switch 
                                                                            v-model="item.emailNewInvoices"
                                                                            disabled
                                                                            hide-details
                                                                            inset
                                                                            :label="item.emailNewInvoices ? 'ON' : 'OFF'" />
                                                                    </v-card-subtitle>
                                                                    <v-card-text>Send emails to customers when new invoices are created.</v-card-text>
                                                                </v-card>
                                                            </template>
                                                        </BT-Entity>
                                                    </v-col>
                                                    <v-col cols="12" sm="4">
                                                        <BT-Entity
                                                            @changed="isChanged => { orderInvoicingSettingsToChange = isChanged; }"
                                                            ignoreID
                                                            navigation="invoicing-settings"
                                                            :saveToggle="saveOrderInvoicingSettingsToggle"
                                                            single>
                                                            <template v-slot="{ item }">
                                                                <v-card
                                                                    class="ma-1 pa-1"
                                                                    outlined>
                                                                    <div class="text-center">
                                                                        <v-icon :class="item.invoiceReminderAutomation == 'Off' ? 'error--text' : 'success--text'" size="50">mdi-robot</v-icon>
                                                                    </div>
                                                                    <v-card-title class="text-h6">Invoice Reminders</v-card-title>
                                                                    <v-card-subtitle>
                                                                        <BT-Select-Automation
                                                                            buttonHeight="45px"
                                                                            isLargeRange
                                                                            v-model="item.invoiceReminderAutomation" />
                                                                    </v-card-subtitle>
                                                                    <v-card-text>How often you want BlitzIt Web to send reminders to customers when invoices are overdue.</v-card-text>
                                                                </v-card>
                                                            </template>
                                                        </BT-Entity>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </template>
                                </BT-Entity>
                            </v-row>
                        </v-container>
                    </template>
                    <template #email>
                        <BT-List-Endless
                            canRefresh
                            :canSearch="false"
                            class="mx-auto"
                            height="280"
                            navigation="email-configurations"
                            label="Email Configurations"
                            :refreshToggle="emailRefreshToggle"
                            width="900">
                            <template v-slot:toolbar-right>
                                <BT-Dialog
                                    @ok="saveConfig"
                                    icon="mdi-plus"
                                    label="Email Configuration"
                                    :showToggle="emailShowToggle"
                                    small
                                    v-model="currentConfiguration"
                                    width="500">
                                    <template slot-scope="{ item }">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6">
                                                    <BT-Field-String
                                                        label="From"
                                                        v-model="item.from"
                                                        isEditing />
                                                </v-col>
                                                <v-col cols="6">
                                                    <BT-Field-String
                                                        label="SMTP Server"
                                                        v-model="item.smtpServer"
                                                        isEditing />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6">
                                                    <BT-Field-String
                                                        label="Port"
                                                        v-model="item.port"
                                                        isEditing />
                                                </v-col>
                                                <v-col cols="6">
                                                    <BT-Field-Select
                                                        label="Security Type"
                                                        v-model="item.securityType"
                                                        isEditing
                                                        :items="['Auto','STARTTLS', 'SSLOnConnect', 'STARTTLSWhenAvailable', 'UseSsl']" />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <BT-Field-String
                                                        label="Sender (Name of sender on email)"
                                                        v-model="item.mailboxName"
                                                        isEditing />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <BT-Field-String
                                                        label="User Name"
                                                        v-model="item.userName"
                                                        isEditing />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <BT-Field-String
                                                        v-if="item.id == null"
                                                        label="Password"
                                                        v-model="item.password"
                                                        isEditing />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </template>
                                </BT-Dialog>
                            </template>
                            <template v-slot="{ item }">
                                <v-container>
                                    <v-row>
                                        <v-spacer />
                                        <v-btn class="error--text" icon small @click="deleteConfiguration(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                                        <v-btn icon small @click="editConfiguration(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="6">
                                            <BT-Field-String
                                                label="From"
                                                v-model="item.from" />
                                        </v-col>
                                        <v-col cols="6">
                                            <BT-Field-String
                                                label="Sender (Name of sender on email)"
                                                v-model="item.mailboxName" />
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="4">
                                            <BT-Field-String
                                                label="SMTP Server"
                                                v-model="item.smtpServer" />
                                        </v-col>
                                        <v-col cols="2">
                                            <BT-Field-String
                                                label="Port"
                                                v-model="item.port" />
                                        </v-col>
                                        <v-col cols="6">
                                            <BT-Field-String
                                                label="Security Type"
                                                v-model="item.securityType" />
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="6">
                                            <BT-Field-String
                                                label="User Name"
                                                v-model="item.userName" />
                                        </v-col>
                                        <v-spacer />
                                        <v-col cols="auto">
                                            <BT-Dialog
                                                buttonClass="primary"
                                                :icon="null"
                                                label="Send Test Email"
                                                text="Send Test Email"
                                                @ok="res => { testConfiguration(item, res) }"
                                                width="350">
                                                <template v-slot="{ item }">
                                                    <BT-Field-String
                                                        hideDetails
                                                        label="Email Address"
                                                        v-model="item.toEmailAddress"
                                                        isEditing />
                                                </template>
                                            </BT-Dialog>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                        </BT-List-Endless>
                    </template>
                    <template #notifications>
                        <v-container style="max-width: 900px;">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Account Notifications</v-card-title>
                                                <v-card-text>Notifications about new invoices, outstanding invoices, and the status of your account are sent to these emails.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { billingSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                navigation="billing-settings"
                                                                :saveToggle="saveBillingSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddresses"
                                                                        label="Email BlitzIt Web Billing Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Supplier Notifications</v-card-title>
                                                <v-card-text>New orders and confirmations from your suppliers will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { customerSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="customer-settings"
                                                                :saveToggle="saveCustomerSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsCustomer"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Customer Notifications</v-card-title>
                                                <v-card-text>Notifications from your customers will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { supplierSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="supplier-settings"
                                                                :saveToggle="saveSupplierSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsSupplier"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Customer Invoicing Notifications</v-card-title>
                                                <v-card-text>Notifications about your billing of customers will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { orderInvoicingSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="invoicing-settings"
                                                                :saveToggle="saveOrderInvoicingSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsSupplier"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Supplier Invoicing Notifications</v-card-title>
                                                <v-card-text>Notifications about invoices you receive from your suppliers will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { orderInvoicingSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="invoicing-settings"
                                                                :saveToggle="saveOrderInvoicingSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsCustomer"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Consignment Notifications</v-card-title>
                                                <v-card-text>Notifications about your orders on consignment will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { stockSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="picking-settings"
                                                                :saveToggle="saveStockSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsCustomer"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Stock Notifications</v-card-title>
                                                <v-card-text>Notifications about your stock levels, etc. will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { stockSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="picking-settings"
                                                                :saveToggle="saveStockSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsSupplier"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Courier Notifications</v-card-title>
                                                <v-card-text>Notifications from your couriers will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { courierSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="courier-settings"
                                                                :saveToggle="saveCourierSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsClient"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2" :disabled="!$isWithinSubscription('COUR')">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Client Notifications</v-card-title>
                                                <v-card-text>Notifications from your customers who use your services as a courier will be sent to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { courierSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="courier-settings"
                                                                :saveToggle="saveCourierSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsCourier"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Courier Billing Notifications</v-card-title>
                                                <v-card-text>Invoices and notifications about your account will be sent from your couriers to these email addresses.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { courierInvoicingSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="courier-invoicing-settings"
                                                                :saveToggle="saveCourierInvoicingSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsClient"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2" :disabled="!$isWithinSubscription('COUR')">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Client Billing Notifications</v-card-title>
                                                <v-card-text>Notifications from your customers who use your services as a courier will be sent to these email addresses.  This covers issues relating to invoices and account status, etc.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { courierInvoicingSettingsToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="courier-invoicing-settings"
                                                                :saveToggle="saveCourierInvoicingSettingsToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Field-Tags
                                                                        v-model="item.emailAddressesAsCourier"
                                                                        label="Email Notifications To"
                                                                        isEditing />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template #subscription>
                        <v-container style="max-width: 900px;">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card class="mb-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                    <v-card-title>Subscription</v-card-title>
                                                    <v-card-text>Depending on what you need, there's a subscription for you.  Our aim is to charge enough in order to continue supplying these services and help you with your logistical needs.  We charge a base fee and then for what you use on top of that.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <BT-Entity
                                                    ignoreID
                                                    navigation="subscription-settings"
                                                    single>
                                                    <template #default="{ item, save }">
                                                        <BT-Entity
                                                            navigation="subscription-plans"
                                                            :itemValue="item.subscriptionCode">
                                                            <template v-slot="eData">
                                                                <v-hover v-slot="{ hover }">
                                                                    <v-card @click="showSub = !showSub" class="ma-2 py-4">
                                                                        <v-row no-gutters>
                                                                            <v-col cols="5" class="d-flex align-center justify-center flex-column">
                                                                                <v-avatar>
                                                                                    <v-icon size="50">mdi-account</v-icon>
                                                                                </v-avatar>
                                                                                <div class="text-h6">{{ eData.item.subscriptionName }}</div>
                                                                            </v-col>
                                                                            <v-col cols="7" class="d-flex align-center justify-center flex-column">
                                                                                <div class="text-body-2">Subscription Fee</div>
                                                                                <div class="text-body-1 font-weight-bold">{{ eData.item.subscriptionFee | toCurrency }} / Month</div>
                                                                                
                                                                                <v-divider v-if="eData.item.orderFee > 0 || eData.item.courierFee > 0 || eData.item.deliveryFee > 0" class="my-4" />
                                                                                
                                                                                <div v-if="eData.item.orderFee > 0 || eData.item.courierFee > 0 || eData.item.deliveryFee > 0" class="text-body-2">+ Pay-Per-Use Fees</div>
                                                                                <div v-if="eData.item.orderFee > 0" class="text-caption font-weight-bold">{{ eData.item.orderFee | toCurrency }} / Purchase Order</div>
                                                                                <div v-if="eData.item.orderFee > 0" class="text-caption font-weight-bold">{{ eData.item.courierFee | toCurrency }} / Courier Order</div>
                                                                                <div v-if="eData.item.orderFee > 0" class="text-caption font-weight-bold">{{ eData.item.deliveryFee | toCurrency }} / Delivery</div>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-fade-transition>
                                                                            <v-overlay v-if="hover" absolute :opacity="0.75" class="text-center">
                                                                                <div><v-icon left>mdi-pencil</v-icon>Change</div>
                                                                                <div class="text-caption my-2">*Changes will be reflected in your monthly billing.</div>
                                                                            </v-overlay>
                                                                        </v-fade-transition>
                                                                    </v-card>
                                                                </v-hover>
                                                            </template>
                                                        </BT-Entity>
                                                        <BT-Select-Dialog
                                                            @change="save"
                                                            hideButton
                                                            listItemValue="id"
                                                            navigation="subscription-plans"
                                                            :onFilter="list => list.orderBy(x => x.subscriptionFee)"
                                                            width="650"
                                                            label="Subscriptions"
                                                            :showToggle="showSub"
                                                            v-model="item.subscriptionCode">
                                                            <template v-slot="{ item }">
                                                                <v-card class="py-4 ma-1" flat outlined width="100%">
                                                                    <v-row no-gutters>
                                                                        <v-col cols="12" sm="5" class="d-flex align-center justify-center flex-column">
                                                                            <v-avatar>
                                                                                <v-icon size="50">mdi-account</v-icon>
                                                                            </v-avatar>
                                                                            <div class="text-h6">{{ item.subscriptionName }}</div>
                                                                        </v-col>
                                                                        <v-col cols="12" sm="7" class="d-flex align-center justify-center flex-column">
                                                                            <div class="text-body-2">Subscription Fee</div>
                                                                            <div class="text-body-1 font-weight-bold">{{ item.subscriptionFee | toCurrency }} / Month</div>
                                                                            
                                                                            <v-divider v-if="item.orderFee > 0 || item.courierFee > 0 || item.deliveryFee > 0" class="my-4" />
                                                                            
                                                                            <div v-if="item.orderFee > 0 || item.courierFee > 0 || item.deliveryFee > 0" class="text-body-2">+ Pay-Per-Use Fees</div>
                                                                            <div v-if="item.orderFee > 0" class="text-caption font-weight-bold">{{ item.orderFee | toCurrency }} / Purchase Order</div>
                                                                            <div v-if="item.orderFee > 0" class="text-caption font-weight-bold">{{ item.courierFee | toCurrency }} / Courier Order</div>
                                                                            <div v-if="item.orderFee > 0" class="text-caption font-weight-bold">{{ item.deliveryFee | toCurrency }} / Delivery</div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card>
                                                            </template>
                                                        </BT-Select-Dialog>
                                                    </template>
                                                </BT-Entity>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="mb-2">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <v-card-title>Billing Invoices</v-card-title>
                                                <v-card-text>Here you will find invoices issued by BlitzIt Tech for monthly subscriptions and any other services provided.</v-card-text>
                                            </v-col>
                                            <v-col cols="12">
                                                <BT-Blade-Items
                                                    addBladeName="subscription-invoice"
                                                    actualHeight="360px"
                                                    bladeName="subscription-invoices"
                                                    :canAdd="false"
                                                    hideActions
                                                    hideBladeHeader
                                                    hideHeader
                                                    :itemsPerPage="10"
                                                    navigation="subscription-invoices"
                                                    :headers="[
                                                        { text: 'INV#', value: 'invoiceNumber' }, 
                                                        { text: 'Issued', value: 'issuedOn', textFilter: 'toShortDate' },
                                                        { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate' },
                                                        { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
                                                        { text: 'Status', value: 'isPaid', display: true }]"
                                                    title="Subscription Invoices"
                                                    useServerPagination>
                                                    <template #isPaid="{ item }">
                                                        <div v-if="item.isPaid" class="success--text">Paid</div>
                                                        <div v-else class="error--text">Unpaid</div>
                                                    </template>
                                                    <template #listItem="{ item }">
                                                        <v-list-item-avatar class="d-flex align-center justify-center text-center">
                                                            <div v-if="item.isPaid" class="success--text">Paid</div>
                                                            <div v-else class="error--text">Unpaid</div>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>#{{ item.invoiceNumber }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ item.issuedOn | toShortDate }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <div>{{ item.amountTotal | toCurrency }}</div>
                                                            <v-list-item-action-text v-if="!item.isPaid">
                                                                due {{ item.dueOn | toRelativeTime }}
                                                            </v-list-item-action-text>
                                                        </v-list-item-action>
                                                    </template>
                                                </BT-Blade-Items>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template #roles>
                        <v-card class="pa-4" flat>
                            <BT-Blade-Items
                                activeClass=""
                                :actualUsedHeight="160"
                                bladeName="roles"
                                hideActions
                                hideBladeHeader
                                hideHeader
                                navigation="roles"
                                showList
                                title="Roles"
                                useServerPagination>
                                <template #body="{ deleteItem, items }">
                                    <v-tabs-items v-model="roleTab">
                                        <v-tab-item>
                                            <v-list>
                                                <v-list-item key="-1" @click="newRole">
                                                    <v-card class="ma-1 primary" dark width="100%">
                                                        <v-card-title><v-icon left>mdi-plus</v-icon>Create New Role</v-card-title>
                                                    </v-card>
                                                </v-list-item>
                                                <v-list-item v-for="(item, ind) in items" :key="ind" @click="selectRole(item)" class="mouse-item">
                                                    <v-card class="ma-1" width="100%">
                                                        <div class="d-flex align-center justify-stretch">
                                                            <v-col cols="11" sm="5">
                                                                <v-card-title>{{ item.roleName }}</v-card-title>
                                                                <v-card-text>{{ item.description }}</v-card-text>
                                                            </v-col>
                                                            <v-col cols="6" class="text-center d-none d-sm-block">
                                                                <div v-for="(p, ind) in permissionToList(item.permissions, 5)" :key="ind">
                                                                    {{ p }}
                                                                </div>
                                                                <div v-if="item.permissions != null && item.permissions.split(',').length > 5" class="grey--text text-caption">
                                                                    +{{ item.permissions.split(',').length - 5 }} other permissions
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="1" class="d-flex align-center">
                                                                <v-btn @click.stop="() => deleteItem(item)" icon class="error--text mouse-over-show"><v-icon>mdi-delete</v-icon></v-btn>
                                                            </v-col>
                                                        </div>
                                                    </v-card>
                                                </v-list-item>
                                            </v-list>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <v-toolbar flat>
                                                <v-btn icon @click="roleTab = 0">
                                                    <v-icon>mdi-arrow-left</v-icon>
                                                </v-btn>
                                                <v-toolbar-title>
                                                    {{ currentRole.roleName || 'New Role' }}
                                                </v-toolbar-title>
                                                <v-spacer />
                                                <v-btn class="primary" @click="saveRole(currentRole, items)"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
                                            </v-toolbar>

                                            <BT-Field-String
                                                isEditing
                                                label="Role Name"
                                                v-model="currentRole.roleName" />

                                            <BT-Field-Text-Area
                                                isEditing
                                                label="Description"
                                                v-model="currentRole.description" />

                                            <v-divider />

                                            <Permissions-Blade v-if="currentRole != null" v-model="currentRole.permissions" />
                                        </v-tab-item>
                                    </v-tabs-items>
                                </template>
                            </BT-Blade-Items>
                        </v-card>
                    </template>
                    <template #integration>
                        <v-container style="max-width: 900px">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Xero Sync For Customer Invoices</v-card-title>
                                                <v-card-text>When invoices are marked as paid in Xero let BlitzIt Web update invoices to be in sync.  This setting only covers invoices that you have issued as a supplier.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { companyToggleToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="company-settings"
                                                                :saveToggle="saveCompanyToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Square-Check
                                                                        v-model="item.useSupplierXeroWebhooks"
                                                                        falseIcon="mdi-sync-off"
                                                                        falseLabel="OFF"
                                                                        isEditing
                                                                        trueIcon="mdi-sync"
                                                                        trueLabel="ON" />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="my-2">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6">
                                                <v-card-title>Xero Sync For Client Invoices</v-card-title>
                                                <v-card-text>When invoices are marked as paid in Xero let BlitzIt Web update invoices to be in sync.  This setting only covers invoices that you have issued as a courier.</v-card-text>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="d-flex align-center justify-center pb-4 pa-sm-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <BT-Entity
                                                                @changed="isChanged => { companyToggleToChange = isChanged; }"
                                                                ignoreID
                                                                inline
                                                                navigation="company-settings"
                                                                :saveToggle="saveCompanyToggle"
                                                                single>
                                                                <template v-slot="{ item }">
                                                                    <BT-Square-Check
                                                                        v-model="item.useCourierXeroWebhooks"
                                                                        falseIcon="mdi-sync-off"
                                                                        falseLabel="OFF"
                                                                        isEditing
                                                                        trueIcon="mdi-sync"
                                                                        trueLabel="ON" />
                                                                </template>
                                                            </BT-Entity>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </BT-Settings-Slider>
            </v-card>
        </template>
    </BT-Blade>
</template>

<script>
import moment from 'moment-timezone';
import { firstBy } from 'thenby';

export default {
    name: 'Company-Settings',
    components: {
        BTColorPickerMenu: () => import('~components/BT-Color-Picker-Menu.vue'),
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        BTSelectAutomation: () => import('~components/BT-Select-Automation.vue'),
        PermissionsBlade: () => import('~home/roles/Permissions-Blade.vue')
    },
    data: function() { 
        return {
            cacheData: new Date().getTime(),
            colorToggle: false,
            currentConfiguration: null,
            currentRole: {},
            dataSets: [],
            emailRefreshToggle: false,
            emailShowToggle: false,
            loadingMsg: null,
            permissions: [],
            personalProfilePanelV: [0, 1],
            roleTab: 0,
            settings: [
                { title: 'Personal Profile' },
                { title: 'Company Profile' },
                { title: 'Email Configurations' },
                { title: 'Notifications' },
                { title: 'Subscription & Billing' },
                { title: 'Roles & Permissions' }
            ],
            showSub: false,
            stage: 0,

            profileToggleToChange: false,
            companyToggleToChange: false,
            billingSettingsToChange: false,
            customerSettingsToChange: false,
            orderInvoicingSettingsToChange: false,
            supplierSettingsToChange: false,
            courierSettingsToChange: false,
            courierInvoicingSettingsToChange: false,
            stockSettingsToChange: false,

            saveProfileToggle: false,
            saveCompanyToggle: false,
            saveBillingSettingsToggle: false,
            saveCustomerSettingsToggle: false,
            saveOrderInvoicingSettingsToggle: false,
            saveSupplierSettingsToggle: false,
            saveCourierSettingsToggle: false,
            saveCourierInvoicingSettingsToggle: false,
            saveStockSettingsToggle: false
        }
    },
    computed: {
        permissionToList() {
            return (permissions, cnt = null) => {
                const e = permissions.split(',');
                if (cnt != null && e.length > cnt) {
                    return [...e.slice(0, cnt)]
                }
                else {
                    return [...e];
                }
            };
        },
        needsSaving() {
            return this.profileToggleToChange ||
                this.companyToggleToChange ||
                this.billingSettingsToChange ||
                this.customerSettingsToChange ||
                this.orderInvoicingSettingsToChange ||
                this.supplierSettingsToChange ||
                this.courierSettingsToChange ||
                this.courierInvoicingSettingsToChange ||
                this.stockSettingsToChange;
        },
        timeZones() {
            return moment.tz.names();
        }
    },
    methods: {
        async saveRole(role, roleList) {
            try {
                this.loadingMsg = 'Saving Changes';

                if (role.id == null) {
                    var res = await this.$BlitzIt.store.post('roles', role);
                    role.rowVersion = res.rowVersion;
                    role.id = res.id;
                    roleList.unshift(role);
                }
                else {
                    var ress = await this.$BlitzIt.store.patch('roles', role);
                    role.rowVersion = ress.rowVersion;
                }
            }
            finally {
                this.loadingMsg = null;
                this.roleTab = 0;
            }
        },
        async selectClientTemplate(item) {
            var res = await this.$selectItem({
                itemText: 'templateName',
                itemValue: 'id',
                navigation: 'client-templates'
            });

            if (res != null) {
                item.defaultTemplateID = res;
            }
        },
        async selectCustomerTemplate(item) {
            var res = await this.$selectItem({
                itemText: 'templateName',
                itemValue: 'id',
                navigation: 'customer-templates'
            });

            if (res != null) {
                item.defaultTemplateID = res;
            }
        },
        newRole() {
            this.currentRole = {
                permissions: null,
                roleName: null,
                description: null
            };
            this.roleTab = 1;
        },
        selectRole(item) {
            this.roleTab = 1;
            this.currentRole = item;
        },
        saveAllChanges() {
            if (this.profileToggleToChange) {
                this.saveProfileToggle = !this.saveProfileToggle;
            }
            if (this.companyToggleToChange) {
                this.saveCompanyToggle = !this.saveCompanyToggle;
            }
            if (this.billingSettingsToChange) {
                this.saveBillingSettingsToggle = !this.saveBillingSettingsToggle;
            }
            if (this.customerSettingsToChange) {
                this.saveCustomerSettingsToggle = !this.saveCustomerSettingsToggle;
            }
            if (this.orderInvoicingSettingsToChange) {
                this.saveOrderInvoicingSettingsToggle = !this.saveOrderInvoicingSettingsToggle;
            }
            if (this.supplierSettingsToChange) {
                this.saveSupplierSettingsToggle = !this.saveSupplierSettingsToggle;
            }
            if (this.courierSettingsToChange) {
                this.saveCourierSettingsToggle = !this.saveCourierSettingsToggle;
            }
            if (this.courierInvoicingSettingsToChange) {
                this.saveCourierInvoicingSettingsToggle = !this.saveCourierInvoicingSettingsToggle;
            }
            if (this.stockSettingsToChange) {
                this.saveStockSettingsToggle = !this.saveStockSettingsToggle;
            }
        },
        loadData(data, nav) {
            if (!this.dataSets.some(x => x.nav == nav)) {
                this.dataSets.push({
                    data: data,
                    nav: nav,
                    toggle: false
                });
            }
        },
        async deleteConfiguration(config) {
            try {
                this.loadingMsg = 'Removing Email Configuration';

                await this.$BlitzIt.store.delete('email-configurations', config.id);

                this.currentConfiguration = null;
                this.emailRefreshToggle = !this.emailRefreshToggle;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        editConfiguration(config) {
            this.currentConfiguration = config;
            this.emailShowToggle = !this.emailShowToggle;
        },
        loadPermissions(user) {
            if (user == null || !this.isLengthyArray(user.roles)) {
                return user;
            }

            this.permissions = user.roles
                .flatMap(x => { return x.permissions.split(','); })
                .reduce((prev, curr) => {
                    var split = curr.split('.');
                    var p = {
                        text: this.capitalizeWords(split[0].replaceAll('-', ' ')),
                        value: split[0],
                        canEdit: false,
                        canView: false
                    }

                    var existing = prev.find(y => y.value == p.value);
                    if (existing == null) {
                        existing = p;
                        prev.push(existing);
                    }

                    if (split.length > 1) {
                        //can view or can edit?
                        if (split[1] == 'edit') {
                            existing.canEdit = true;
                        }
                        if (split[1] == 'view') {
                            existing.canView = true;
                        }
                    }

                    return prev;
                }, [])
                .sort(firstBy(x => x.text, { ignoreCase: true }));

            return user;
        },
        async saveConfig(res) {
            try {
                this.loadingMsg = 'Saving Email Configuration';

                if (res.id == null) {
                    //create new
                    await this.$BlitzIt.store.post('email-configurations', res);
                }
                else {
                    //update existing
                    await this.$BlitzIt.store.patch('email-configurations', res);
                }

                this.currentConfiguration = null;
                this.emailRefreshToggle = !this.emailRefreshToggle;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async testConfiguration(config, res) {
            try {
                this.loadingMsg = 'Sending Test';
                await this.$BlitzIt.api.getById('email-configurations', null, null, null, `/Test/Test?ID=${config.id}&toEmailAddress=${res.toEmailAddress}`)
                await this.$alert('Done');
            }
            catch (err) {
                await this.$alert(this.extractErrorDescription(err));
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>